import React from 'react';

import './SubmitButton.scss';

const submitButton = () => {
  return (
    <button id="SubmitButton">GO</button>
  )
}

export default submitButton;